import {graphql} from 'gatsby'
import {useEffect, useRef, useState} from 'react'
import {useLocation} from 'react-use'

import ArticleContent from 'src/components/ArticleContent'
import {Box, Grid} from 'src/components/Box'
import CourseHeader from 'src/components/Course/CourseHeader'
import CourseStepList from 'src/components/Course/CourseList'
import Layout from 'src/components/Layout'
import LoginModal from 'src/components/LoginModal'
import useViewer from 'src/hooks/useViewer'
import {FlairProvider} from 'src/context/FlairContext'
import VideoType from 'src/components/Course/VideoType'
import ShortAnswer from 'src/components/Course/ShortAnswer'
import CourseCompletedModal from 'src/components/Course/CourseCompletedModal'
import useCourseCompletedInteraction from 'src/hooks/useCourseCompletedInteraction'
import {useContentfulCompletedTasks} from 'src/hooks/useCourseCompletedTask'
import useCourseDatapoint from 'src/hooks/useCourseDatapoint'

export const query = graphql`
  query CourseTemplatePageData($id: String) {
    contentfulTasks(contentful_id: {eq: $id}) {
      ...CourseFragment
    }
  }
`

const CoursePageContext = ({data}: any) => {
  const {
    contentful_id = '',
    title = '',
    featuredImageUrl = '',
    description,
    steps,
  } = {
    ...data.contentfulTasks,
    steps: data.contentfulTasks.steps.map((step: any) => ({
      ...step,
      description: step.description?.description || '',
    })),
    description: data.contentfulTasks.description?.description || '',
  }

  const trackCourseDatapoint = useCourseDatapoint()

  const {viewer} = useViewer()
  const completedSteps = useContentfulCompletedTasks(contentful_id)
  const [showButton, setShowButton] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [showCourseCompletedModal, setShowCourseCompletedModal] =
    useState(false)
  const [modalCss, setModalCss] = useState({})
  const [clickedSteps, setClickedSteps] = useState<number[]>([])
  const [clickedStepContent, setClickedStepContent] = useState<any | {}>({})
  const location = useLocation()
  const createInteraction = useCourseCompletedInteraction()
  const status = useRef<string[]>([])
  const lastClickedIndex = useRef(-1)

  const loginStep = {
    title: steps[0].title,
    description: steps[0].description,
    imageUrl: steps[0].imageUrl,
    averageDuration: 3,
    asaPoints: 1000,
    type: 'Account',
    status: !viewer || viewer === 'not-logged-in' ? 'inprogress' : 'completed',
    onClick: () => showLoginModal(-1), // You can use -1 or any unique identifier for loginStep
  }

  steps[0] = loginStep

  function removeLoginModal() {
    setModalCss({})
    setShowModal(false)
  }

  const handleScroll = () => {
    const scrollPosition = window.scrollY
    const pageHeight =
      document.documentElement.scrollHeight - window.innerHeight
    const threeFourthPoint = (3 / 4) * pageHeight
    if (scrollPosition > threeFourthPoint) {
      setShowButton(true)
      window.removeEventListener('scroll', handleScroll)
    }
  }

  function showButtonSetter(contentType: string) {
    if (
      contentType === 'ContentfulArticleTile' ||
      contentType === 'ContentfulVideoContent'
    ) {
      setShowButton(false)
      if (contentType === 'ContentfulArticleTile') {
        window.addEventListener('scroll', handleScroll)
        setTimeout(() => {
          setShowButton(true)
        }, 45000)
      } else {
        setTimeout(() => {
          setShowButton(true)
        }, 60000)
      }
    }
  }

  function showLoginModal(_index: number) {
    setModalCss({
      pointerEvents: 'none',
      opacity: 0.3,
    })
    setShowModal(true)
  }

  const videoProps = (
    mainTitle: any,
    title: any,
    videoLink: any,
    videoDescription: any,
    setShowButton: any
  ) => {
    return {
      mainTitle: mainTitle,
      title: title,
      videoLink: videoLink,
      videoDescription: videoDescription,
      setShowButton: setShowButton,
    }
  }

  useEffect(() => {
    if (viewer && viewer !== 'not-logged-in' && completedSteps?.length === 0) {
      trackCourseDatapoint(contentful_id, title, 'start', 'getschooled')
    }
  }, [viewer, completedSteps])

  function handleOtherStepClick(index: number, step: any) {
    if (
      !clickedSteps.includes(index) &&
      index === lastClickedIndex.current + 1
    ) {
      window.scrollTo(0, 0)
      const updatedStatus: string[] = [...status.current]
      updatedStatus[index] =
        !viewer || viewer === 'not-logged-in' ? 'inprogress' : 'completed'

      status.current = updatedStatus
      lastClickedIndex.current = index
      setClickedSteps((prev) => [...prev, index])
      showButtonSetter(step.content?.__typename)
      setClickedStepContent(step)

      if (
        step.content.__typename !== 'ContentfulSelfReflectionQuestions' ||
        completedSteps?.length !== newSteps.length - 2
      ) {
        createInteraction({
          variables: {
            taskId: contentful_id,
            stepId: step.contentful_id,
          },
        })
      }
    }
  }

  const stepsWithStatusAndOnClick = steps
    .slice(1)
    .map((step: any, index: number) => {
      return {
        ...step,
        status: status.current[index] || 'disabled',
        onClick: () => handleOtherStepClick(index, step),
      }
    })

  const newSteps = [loginStep, ...stepsWithStatusAndOnClick]
  const stepCount = steps.length

  useEffect(() => {
    if (completedSteps && completedSteps.length > 0) {
      const completedStepIds = completedSteps.map(
        (completedStep) => completedStep.stepId
      )
      if (completedSteps?.length === newSteps.length - 1) {
        setShowCourseCompletedModal(true)
        setModalCss({
          pointerEvents: 'none',
          opacity: 0.3,
        })
        setShowButton(false)
      } else {
        for (let index = 0; index < steps.length; index++) {
          const step = steps[index]
          if (completedStepIds.includes(step.contentful_id)) {
            handleOtherStepClick(index - 1, step)
          }
        }
      }
    }
  }, [completedSteps])

  return (
    <Layout>
      <CourseCompletedModal
        showModal={showCourseCompletedModal}
        setModal={setShowCourseCompletedModal}
      />
      <LoginModal
        showModal={showModal}
        closeModal={removeLoginModal}
        locationPathname={location.pathname || ''}
        fromModal="courses"
      >
        Unlock ALL
        <br />
        Get Schooled Courses!
      </LoginModal>
      <Box css={{position: 'relative', ...modalCss}}>
        {clickedStepContent.content?.__typename === 'ContentfulArticleTile' ? (
          <FlairProvider seed={parseInt(clickedStepContent.content.id, 16)}>
            <Grid
              gridTemplateColumns={{lg: '1fr'}}
              gridTemplateRows={{lg: 'auto auto'}}
              gridColumnGap="5"
            >
              <Grid gridGap="5">
                <ArticleContent tile={clickedStepContent.content} />
                {/* Main content container */}
              </Grid>
            </Grid>
          </FlairProvider>
        ) : clickedStepContent.content?.__typename ===
          'ContentfulVideoContent' ? (
          <FlairProvider seed={parseInt(clickedStepContent.content.id, 16)}>
            {' '}
            <VideoType
              {...videoProps(
                title,
                clickedStepContent.content?.title,
                clickedStepContent.content?.videoLink,
                clickedStepContent.content?.description?.description || '',
                setShowButton
              )}
            />{' '}
          </FlairProvider>
        ) : clickedStepContent.content?.__typename ===
          'ContentfulSelfReflectionQuestions' ? (
          <ShortAnswer
            question={clickedStepContent.content?.question.question}
            description={
              clickedStepContent.content?.description?.description || ''
            }
            contentfulId={contentful_id}
            stepId={clickedStepContent.contentful_id}
            onCompletion={() =>
              trackCourseDatapoint(contentful_id, title, 'end', 'getschooled')
            }
          />
        ) : (
          <CourseHeader
            title={title}
            description={description}
            featuredImageUrl={featuredImageUrl}
            stepCount={stepCount}
          />
        )}
        <CourseStepList
          contentTypeName={clickedStepContent.content?.__typename}
          showButton={showButton}
          title="Course Playlist"
          steps={newSteps}
        />
      </Box>
    </Layout>
  )
}

export default CoursePageContext
