import React, {useRef, useEffect} from 'react'
import {navigate} from 'gatsby'

import {Box} from 'src/components/Box'
import * as breakpoints from 'src/styles/mediaQuery'
import {Body} from 'src/components/text'

type CourseCompletedModalProps = {
  showModal: boolean
  setModal: any
}

const CourseCompletedModal: React.FC<CourseCompletedModalProps> = ({
  showModal,
  setModal,
}) => {
  const modalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const modalElement = modalRef.current
    if (modalElement && showModal) {
      modalElement.style.transition = 'left 3s ease-in-out'
      modalElement.style.left = '50%'
      setTimeout(() => {
        modalElement.style.left = '100%'
      }, 5000)
      setTimeout(() => {
        setModal(false)
        navigate('/courses')
      }, 8000)
    }
  }, [showModal])

  return (
    <>
      {showModal && (
        <Box
          ref={modalRef}
          position="absolute"
          zIndex={1}
          mt={200}
          alignSelf="center"
          css={{
            borderRadius: 15,
            left: '100%', // Start from the right side of the screen
            maxWidth: '400px',
            width: '100%',
            backgroundColor: 'blue',

            [breakpoints.medium]: {
              maxWidth: 'none',
              width: 650,
            },
            transform: 'translateX(-50%)',
          }}
        >
          <Body
            fontSize={{md: '2rem', default: '0.85rem'}}
            css={{
              borderRadius: 15,
              backgroundImage:
                'linear-gradient(180deg, #E5E5E5 0.03%, #12B3D8 0.04%, #2494C7 37.52%, #2F82BD 54.03%, #3666AE 85.03%, #385BA9 100.01%)',
              borderColor: 'blue',
              color: 'white',
              padding: 20,
            }}
            textAlign="center"
          >
            You completed the activity! Keep going!
          </Body>
        </Box>
      )}
    </>
  )
}

export default CourseCompletedModal
